<template>
  <div id="bank_form" v-if="bankData">
    <div class="d-flex">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ bankData.id == 0 ? "ADD" : "EDIT" }} BANK
      </h3>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <!-- Field: Bank Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Bank Name"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Bank Name"
                  label-for="name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="name"
                    placeholder=""
                    :state="getValidationState(validationContext)"
                    v-model="bankData.name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="date_of_agreement"
                rules="required"
              >
                <b-form-group
                  class="date_of_agreement required"
                  label="Date of Referral Agreement"
                  label-for="date_of_agreement"
                  label-cols-md="4"
                >
                  <flat-pickr
                    v-model="bankData.date_of_agreement"
                    placeholder="DD-MM-YYYY"
                    class="form-control"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                class="remarks"
                label="Remarks"
                label-for="remarks"
                label-cols-md="4"
              >
                <b-form-input
                  id="remarks"
                  placeholder=""
                  v-model="bankData.remarks"
                /> </b-form-group
            ></b-col>

            <b-col cols="12" md="6">
              <b-form-group
                class="date_of_termination"
                label="Date of Termination"
                label-for="date_of_termination"
                label-cols-md="4"
              >
                <flat-pickr
                  v-model="bankData.date_of_termination"
                  placeholder="DD-MM-YYYY"
                  class="form-control"
                  :config="flatDateConfig"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div
            ref="form"
            class="invoice-items repeater-form"
            :style="{ height: trHeight }"
          >
            <b-row
              v-for="(item, index) in bankData.contents"
              :key="index"
              ref="row"
              class="pb-2"
            >
              <b-col cols="12">
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :vid="'user' + index"
                        name="User"
                        rules="required"
                      >
                        <b-form-group
                          class="authorization_type_id required"
                          :label="
                            authorizationTypes &&
                            item.authorization_type_id &&
                            authorizationTypes.find(
                              (type) => type.id == item.authorization_type_id
                            ) &&
                            authorizationTypes.find(
                              (type) => type.id == item.authorization_type_id
                            ).name
                          "
                          :label-for="`user_id_${index}`"
                          label-cols-md="4"
                        >
                          <div class="form-col-select">
                            <v-select
                              :id="`user_id_${index}`"
                              v-model="item.user_id"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="userOptions"
                              label="name"
                              :reduce="(option) => option.id"
                              :clearable="false"
                            >
                              <!-- <template slot="option" slot-scope="option">
                                [{{ option.leave_code }}] {{ option.description }}
                                </template> -->
                            </v-select>
                            <feather-icon size="18" icon="ChevronDownIcon" />
                          </div>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        name="date_of_authorised"
                        :vid="'date_of_authorised' + index"
                        rules="required"
                      >
                        <b-form-group
                          class="date_of_authorised required"
                          label="Date of Being Authorised"
                          label-for="date_of_authorised"
                          label-cols-md="4"
                        >
                          <flat-pickr
                            v-model="item.date_of_authorised"
                            class="form-control"
                            placeholder="DD-MM-YYYY"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <b-form-group
                        class="remarks"
                        label="Remarks"
                        label-for="remarks"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="remarks"
                          placeholder=""
                          v-model="item.remarks"
                        /> </b-form-group
                    ></b-col>

                    <b-col cols="12" md="6">
                      <b-form-group
                        class="date_of_removed"
                        label="Date of Being Removed"
                        label-for="date_of_removed"
                        label-cols-md="4"
                      >
                        <flat-pickr
                          v-model="item.date_of_removed"
                          class="form-control"
                          placeholder="DD-MM-YYYY"
                          :config="flatDateConfig"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div
                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                  >
                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeItem(index, item)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <div
            ref="form"
            class="repeater-form"
            :style="{ height: trHeight }"
          ></div>
          <b-form-group
            class="authorization_type_id"
            label="Add Authorised Person"
            label-for="authorization_type_id"
          >
            <b-row class="align-items-center">
              <b-col cols="12" md="8">
                <v-select
                  id="authorization_type_id"
                  v-model="authorization_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="authorizationTypes"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="true"
                  placeholder="Select the type of authorised person"
                >
                </v-select>
              </b-col>
              <b-col cols="12" md="4">
                <b-button
                  class="mt-1 mt-md-0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                  v-if="authorization_type_id"
                >
                  Add
                  {{
                    authorization_type_id &&
                    authorization_type_id &&
                    authorizationTypes.find(
                      (type) => type.id == authorization_type_id
                    ).name
                  }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import { heightTransition } from "@core/mixins/ui/transition";
import { getUserData } from "@/auth/utils";

export default {
  data() {
    return {
      user_id: getUserData().id,
      authorization_type_id: null,
    };
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-bank/addBank", this.bankData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$router.replace("/banks");

              if (this.bankData.id == 0) {
                // Return to client list
                //this.hide();
                //	sp1.hk.cmbintl.com
                this.bankData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    hide() {
      this.$router.replace("/banks");
    },
    redirect(id) {
      this.$router.replace("/banks/edit/" + id);
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.itemFormBlankItem.authorization_type_id = this.authorization_type_id;
      this.bankData.contents.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );
      // this.$nextTick(() => {
      //     this.trAddHeight(this.$refs.row[0].offsetHeight)
      //     setTimeout(() => {
      //         this.$refs.form.style.overflow = null
      //     }, 350)
      // })
    },
    removeItem(index, item) {
      // count the authorizationType
      let same_type_record = this.bankData.contents.filter(
        (record) => record.authorization_type_id == item.authorization_type_id
      );
      let type = this.authorizationTypes.find(
        (type) => type.id == item.authorization_type_id
      ).name;

      console.log();

      // if (same_type_record.length <= 1) {
      //   this.makeToast(
      //     "danger",
      //     "Warning",
      //     "Please submit at least one " + type + " record."
      //   );
      // } else {
      if (item.id > 0) {
        this.$swal({
          title: "Are you sure?",
          text: "Your action is final and you will not be able to retrieve the authorised person.",
          showCancelButton: true,
          confirmButtonText: "Delete",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("app-bank/deleteAuthorisedPerson", { id: item.id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.refetchData();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      } else {
        this.bankData.contents.splice(index, 1);
      }
      // }
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  props: {
    bankData: {
      type: Object,
      required: true,
    },
    authorizationTypes: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const button_text = ref("Save");
    const resetbankData = () => {
      props.bankData = JSON.parse(JSON.stringify({}));
    };
    const itemFormBlankItem = {
      id: 0,
      authorization_type_id: null,
      date_of_authorised: null,
      date_of_removed: null,
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetbankData);
    return {
      button_text,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      itemFormBlankItem,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
