<template>
  <component :is="bankData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="bankData === undefined">
      <h4 class="alert-heading">Error fetching bank data</h4>
      <div class="alert-body">
        No bank found with this bank id. Check
        <b-link class="alert-link" :to="{ name: 'banks-list' }"> Bank List </b-link>
        for other banks.
      </div>
    </b-alert>
    <bank-edit-tab-information
      :bank-data="bankData"
      :authorization-types="authorizationTypes"
      :user-options="userOptions"
      @refetch-data="refetchData"
      class="m-sm-2"
    />
  </component>
</template>
<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import bankStoreModule from "../bankStoreModule";
import BankEditTabInformation from "./BankEditTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BankEditTabInformation,
  },
  setup() {
    const bankData = ref({});

    const BANK_STORE_MODULE_NAME = "app-bank";

    const bank = {
      id: 0,
      name: "",
      user_id: null,
      date_of_agreement: null,
      date_of_termination: null,
      remarks: null,
      description: null,
      contents: [],
    };

    if (!store.hasModule(BANK_STORE_MODULE_NAME))
      store.registerModule(BANK_STORE_MODULE_NAME, bankStoreModule);

    onUnmounted(() => {
      if (store.hasModule(BANK_STORE_MODULE_NAME))
        store.unregisterModule(BANK_STORE_MODULE_NAME);
    });

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const refetchData = () => {
      if (router.currentRoute.name == "banks-create") {
        bankData.value = bank;
      } else {
        store
          .dispatch(BANK_STORE_MODULE_NAME + "/fetchBank", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            bankData.value = response.data.bank;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              bankData.value = undefined;
            }
          });
      }
    };

    const authorizationTypes = ref([]);
    const userOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", { authorization_type: true, user: true })
        .then((response) => {
          let authorization_types = response.data.authorization_types;
          authorizationTypes.value = response.data.authorization_types;
          userOptions.value = response.data.users;
          console.log({ authorization_types });
          if (router.currentRoute.name == "banks-create") {
            bankData.value.contents = [{
                id: 0,
                authorization_type_id: 1,
                date_of_authorised: null,
                date_of_removed: null,
                remarks: null,
              }];
          }
        });
    };

    return {
      refetchData,
      refetchOption,
      bankData,
      authorizationTypes,
      userOptions,
    };
  },
};
</script>
